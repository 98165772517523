import {
  Container,
  Button,
  Grid,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { tokenConfig, useUserDispatch } from "../../context/UserContext";
import { BASE_URL } from "../../healpers/api";
import PageTitle from "../../components/PageTitle/PageTitle";
import useStyles from "./styles";
import { Modal } from "react-responsive-modal";
import marketPlaceData from "../../data/spMarketPlaceIds.json";
import _ from "lodash";
import { updateCurrentIntegration } from "../../context/UserContext";
import { usePopupState, usePopupDispatch } from "../../context/PopupContext";
import Alert from "@material-ui/lab/Alert";
import { Delete as DeleteIcon } from "@material-ui/icons";
import api from "../../healpers/apiRoutes";

export default function ConnectSellerCentral() {
  const classes = useStyles();

  const [user, setUser] = useState(null);
  const [open, setModalOpen] = useState(false);
  const [marketPlace, setMarketPlace] = useState("");
  const [marketPlaceID, setMarketPlaceID] = useState("");
  const [vendorCentralUrl, setVendorCentralUrl] = useState("");
  const [sellerCentralUrl, setSellerCentralUrl] = useState("");
  const [integrationType, setIntegrationType] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [country, setCountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [accountNameHelperText, setAccountNameHelperText] = useState("");

  const popupDispatch = usePopupDispatch();
  const { err, notification } = usePopupState();

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/user/fromToken`, tokenConfig())
      .then((res) => {
        setUser(res.data);
        if (res.data.vendorDetails?.length > 0){
          setAccountName(res.data.vendorDetails[0]?.customername)
        }
        setAccountName(res.data.currentIntegration?.accountName)
      })
      .catch((err) => {
        console.log(err);
      });
      // filterMarketPlaces()
    return () => {
      resetValues();
    };
  }, []);

  useEffect(() => {
    integrationList()
  }, [user])

  useEffect(() => {
    const restrictedTerms = ["seller central", "vendor central"];
    if (restrictedTerms.includes(accountName?.trim()?.toLowerCase())) {
      setAccountNameHelperText(`Account name not allowed. Kindly remove the restricted terms if present [${restrictedTerms.join(",")}]`);
    } else {
      setAccountNameHelperText("");
    }
  }, [accountName]);

  const handleSubmit = async () => {
    await updateCurrentIntegration(
      { integrationType, marketPlaceID, countryCode, country, id: user._id, accountName },
      usePopupDispatch,
      setIsLoading
    );
    if (integrationType === "VENDOR") {
      window.location.href = `${vendorCentralUrl}/apps/authorize/consent?application_id=amzn1.sp.solution.6cf699bd-f89a-4afd-b64e-7d21351a4fad&state=9661f8c828a3a5761f89`;
      console.log(
        `${vendorCentralUrl}/apps/authorize/consent?application_id=amzn1.sp.solution.6cf699bd-f89a-4afd-b64e-7d21351a4fad&state=9661f8c828a3a5761f89`
      );
    } else {
      window.location.href = `${sellerCentralUrl}/selling-partner-appstore/dp/amzn1.sp.solution.6cf699bd-f89a-4afd-b64e-7d21351a4fad`;
      console.log(
        `${sellerCentralUrl}/selling-partner-appstore/dp/amzn1.sp.solution.6cf699bd-f89a-4afd-b64e-7d21351a4fad`
      );
    }
  };

  const integrationList = async () => {
    try{
      setfullPageLoader(true);
      const body = {
       email: user.email
      }
      const response = await axios.post(BASE_URL + api.fetchIntegrations, body);
      setIntegrations(response?.data?.data || [])
      filterMarketPlaces()
    } catch(error) {
      console.log(error)
    } finally {
      setfullPageLoader(false);
    }
  }

  const filterMarketPlaces = () => {
    const ids = integrations.map((item)=> item.marketplaceId)
    const marketplaces = _.filter(marketPlaceData, (item) => !ids.includes(item.id))
    // console.log("marketplaces", marketplaces)
  }

  const resetValues = () => {
    setMarketPlace("");
    setMarketPlaceID("");
    setVendorCentralUrl("");
    setSellerCentralUrl("");
    setIntegrationType("");
    setCountryCode("");
    setCountry("");
  };

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Integrations"
          rightAlignComponent={
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                flexWrap: "nowrap",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => setModalOpen(true)}
              >
                Add integration
              </Button>
            </Grid>
          }
        />
        <Grid container spacing={4}>
         {integrations.length > 0 ?integrations.map((integration) => (
           <Grid item xs={4}>
           <Card>
             <CardContent>
               <Grid
                 container
                 style={{
                   justifyContent: "space-between",
                   alignItems: "center",
                 }}
               >
                 <Grid item>
                   <Typography color="text.primary" gutterBottom variant="h4">
                     {integration.type === "vendor" ? "Vendor" : "Seller"} Central
                   </Typography>
                 </Grid>
                 <Grid item>
                   <DeleteIcon
                     onClick={() => setOpenDialog(true)}
                     color="primary"
                     style={{ cursor: "pointer" }}
                   />
                 </Grid>
               </Grid>
               <Typography
                 sx={{ fontSize: 14 }}
                 color="text.primary"
                 gutterBottom
               >
                 Marketplace ID - {integration.marketplaceId}
               </Typography>
               <Typography
                 sx={{ fontSize: 14 }}
                 color="text.primary"
                 gutterBottom
               >
                 Country Code - {integration.countryCode}
               </Typography>
             </CardContent>
           </Card>
         </Grid>
         )):<Typography
         style={{
           fontSize: "14px",
           textAlign: "center",
           fontWeight: 400,
           paddingLeft: 20,
           paddingTop: 20
         }}
       >
         No integrations found.
       </Typography> }
        </Grid>
        <Modal
          open={open}
          onClose={() => {
            setModalOpen(false);
            resetValues();
          }}
          styles={{ modal: { width: 500 } }}
          center
        >
          <Grid container spacing={4} style={{ width: "100%" }}>
            <Grid
              item
              xs={12}
              style={{ justifyContent: "flex-start", display: "flex" }}
            >
              <Typography
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                Add Integration
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  value={accountName}
                  type="text"
                  onChange={(e) => {
                    setAccountName(e.target.value);

                  }}
                  label=" Account Name"
                  className={classes.select}
                  helperText={accountNameHelperText}
                >
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Market Place
                </InputLabel>
                <Select
                  value={marketPlace}
                  onChange={(e) => {
                    setMarketPlace(e.target.value);
                    setMarketPlaceID(e.target.value.id);
                    setVendorCentralUrl(e.target.value.vendorCentralUrl);
                    setSellerCentralUrl(e.target.value.sellerCentralUrl);
                    setCountryCode(e.target.value.countryCode);
                    setCountry(e.target.value.country);
                  }}
                  label="Select Market Place"
                  className={classes.select}
                >
                  {marketPlaceData.map((item) => {
                    return <MenuItem value={item}>{item.country}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Integration
                </InputLabel>
                <Select
                  value={integrationType}
                  onChange={(e) => {
                    setIntegrationType(e.target.value);
                  }}
                  label="Select Market Place"
                  className={classes.select}
                >
                  <MenuItem value={"VENDOR"}>VENDOR CENTRAL</MenuItem>;
                  <MenuItem value={"SELLER"}>SELLER CENTRAL</MenuItem>;
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={
                    integrationType.length === 0 || _.isEmpty(marketPlace) || accountNameHelperText
                  }
                >
                  Integrate
                </Button>
              )}
            </Grid>
          </Grid>
        </Modal>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to remove this integration?
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button onClick={() => {}} variant="contained" color="primary">
              Remove
            </Button>
          </DialogActions>
        </Dialog>
        {notification ? (
          <Alert
            severity="success"
            style={{ position: "absolute", bottom: 30, left: "48%" }}
          >
            {notification}
          </Alert>
        ) : (
          <></>
        )}
        {err ? (
          <Alert
            severity="error"
            style={{ position: "absolute", bottom: 30, left: "48%" }}
          >
            {err}
          </Alert>
        ) : (
          <></>
        )}
      </Container>
    </>

  );
}
