import React, { forwardRef } from "react";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'

export default forwardRef ( function Tables(props) {
  return (
    <div className="ag-theme-material ams-campaigns" style={{height: 620, width: '100%'}} id="#grid-theme-wrapper">
      <AgGridReact
        rowData={props.tableData}
        suppressExcelExport={true}
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable:true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        pagination={true}
        paginationPageSize={50}
        rowStyle={{ background: 'white' }}
        fullWidth
        suppressDragLeaveHidesColumns={true}
        suppressLoadingOverlay
      >
        <AgGridColumn headerName="Campaign" field="name" width={290}/>
        <AgGridColumn headerName="ACOS" field="report.ACOS" width={100} valueFormatter = {calcACOS}/>
        <AgGridColumn headerName="Impressions" field="report.Impressions" width={120} valueFormatter = {intParse} comparator={sortNumber}/>
        <AgGridColumn headerName="Sales" field="report.Sales(USD)" width={100} valueFormatter = {(params) => floatParseWithSymbol(params, "$")} comparator={sortNumber}/>
        <AgGridColumn headerName="Spend" field="report.Spend(USD)" width={100} valueFormatter = {(params) => floatParseWithSymbol(params, "$")} comparator={sortNumber}/>
        <AgGridColumn headerName="Clicks" field="report.Clicks" width={80} valueFormatter = {intParse} comparator={sortNumber}/>
        <AgGridColumn headerName="Orders" field="report.Orders" width={100} valueFormatter = {intParse} comparator={sortNumber}/>
      </AgGridReact>
    </div>
  );
})

const calcACOS = (params) => params.value ? `${(parseFloat(params.value) * 100).toFixed(2)}%` : "-";
const intParse = (params) => params.value ? (parseInt(params.value)) : "-";
const floatParseWithSymbol = (params, symbol) => params.value ? (symbol + Number(parseFloat(params.value)).toFixed(2)) : "-";
const sortNumber = (a, b) => a - b;
