import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import RefreshIcon from "@material-ui/icons/Refresh";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx";
import SEOUpdateCheckTemplate from "../../healpers/assets/SEOUpdateCheckTemplate.xlsx";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";

import PageTitle from "../../components/PageTitle/PageTitle";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import useStyles from "./styles";
import { useUserState } from "../../context/UserContext";

const ASIN_CHECKING_INTERVAL = 6; //in seconds --> 6 (1+5) => 1s for delay between asin, 5s for each asin processing

export default function SEOUpdateCheck() {
  const classes = useStyles();
  const userState = useUserState();

  const [rowData, setRowData] = useState([]);
  const [isLoadingRowData, setIsLoadingRowData] = useState(true);
  const [uploadFile, setUploadFile] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const loadRowData = async () => {
    try {
      setIsLoadingRowData(true);
      const seoUpdateChecks = await axios.get(
        BASE_URL + api.fetchSeoUpdateChecks
      );
      setIsLoadingRowData(false);
      if (seoUpdateChecks.data?.status === "Success") {
        setRowData(seoUpdateChecks.data.data);
      } else {
        window.alert(
          seoUpdateChecks.data?.message || "Failed to load data. Try again."
        );
        setRowData([]);
      }
      setIsLoadingRowData(false);
    } catch (e) {
      console.log(e);
      window.alert("Failed to load data. Try again.");
      setIsLoadingRowData(false);
      setRowData([]);
    }
  };

  const handleDialogClose = () => {
    setShowDialog(false);
    setDialogMessage("");
    setUploadFile(null);
  };

  const SEOStatusRenderer = (props) => {
    return (
      <>
        {Object.entries(props.data.seoStatus)
          .map((item) => `${item[0]}:${item[1]}`)
          .join(", ")}
      </>
    );
  };

  const ActionButtonsRenderer = (props) => {
    return (
      <div
        style={{
          display: "flex",
          gap: "8px",
          paddingTop: "8px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={["PENDING", "IN_PROGRESS"].includes(props.data.status)}
          onClick={async () => {
            try {
              const retry = await axios({
                method: "post",
                url: BASE_URL + api.retrySeoUpdateChecks,
                data: {
                  id: props.data.id,
                },
              });
              if (retry.data?.status === "Success") {
                window.alert(retry.data?.message);
                await loadRowData();
              } else {
                window.alert(
                  retry.data?.message || "Failed to initiate retry."
                );
              }
            } catch (e) {
              console.log(e);
              window.alert("Failed to initiate retry.");
            }
          }}
        >
          Retry
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={props.data.status !== "COMPLETED"}
          onClick={() => {
            const downloadURL = `${BASE_URL}${api.downloadSeoUpdateChecks}/${props.data.name}`;
            window.open(downloadURL, "_blank", "noopener,noreferrer");
          }}
        >
          Download
        </Button>
      </div>
    );
  };

  useEffect(() => {
    loadRowData();
  }, []);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="SEO Update Check"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Catalog
              </Link>
              <Typography className={classes.link} color="primary">
                SEO Update Check
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <div
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "end",
              }}
            >
              <a
                href={SEOUpdateCheckTemplate}
                download="SEOUpdateCheckTemplate.xlsx"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                  component="span"
                  startIcon={<PrintIcon />}
                >
                  Download Template
                </Button>
              </a>
              <FormControl variant="outlined">
                <label htmlFor="upload-xlsx">
                  <input
                    style={{ display: "none" }}
                    id="upload-xlsx"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.onload = (event) => {
                        try {
                          const workbook = XLSX.read(event.target.result, {
                            type: "array",
                          });
                          const worksheetName = workbook.SheetNames[0];
                          const worksheet = workbook.Sheets[worksheetName];
                          const data = XLSX.utils.sheet_to_json(worksheet);
                          if (data.length > 0) {
                            let isFileValid = true;
                            for (let index = 0; index < data.length; index++) {
                              if (!data[index]["ASIN"]) {
                                window.alert(
                                  `Missing ASIN field on row ${
                                    index + 1
                                  }. Check the file and Try again.`
                                );
                                isFileValid = false;
                                break;
                              }

                              if (
                                !data[index]["Title"] &&
                                !data[index]["BP1"] &&
                                !data[index]["BP2"] &&
                                !data[index]["BP3"] &&
                                !data[index]["BP4"] &&
                                !data[index]["BP5"] &&
                                !data[index]["Description"]
                              ) {
                                window.alert(
                                  `Atleast 1 SEO attribute is required to check on row ${
                                    index + 1
                                  }. Check the file and Try again.`
                                );
                                isFileValid = false;
                                break;
                              }
                            }
                            if (isFileValid) {
                              const totalTimeInSeconds =
                                data.length * ASIN_CHECKING_INTERVAL;
                              const hours = Math.floor(
                                totalTimeInSeconds / 60 / 60
                              );
                              const minutes = Math.floor(
                                totalTimeInSeconds / 60
                              );
                              const seconds = totalTimeInSeconds % 60;
                              const formattedTime = `${hours}h:${minutes}m:${seconds}s`;
                              setUploadFile(file);
                              setShowDialog(true);
                              setDialogMessage(
                                `ASINs found on the file: ${data.length}. Expected time to process: ${formattedTime}.`
                              );
                            }
                          } else {
                            window.alert("File is empty. Try again.");
                          }
                        } catch (err) {
                          console.error(err);
                          window.alert(
                            err?.message || "File validation failed."
                          );
                        }
                      };
                      reader.readAsArrayBuffer(e.target.files[0]);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                    startIcon={<CloudUploadIcon />}
                    component={"span"}
                  >
                    Upload
                  </Button>
                </label>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={loadRowData}
                startIcon={<RefreshIcon />}
              >
                Refresh
              </Button>
            </div>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12} style={{ backgroundColor: "white" }}>
            {isLoadingRowData ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div
                className="ag-theme-material"
                style={{ height: 550, width: "100%" }}
                id="#grid-theme-wrapper"
              >
                <AgGridReact
                  rowData={rowData}
                  suppressExcelExport={true}
                  defaultColDef={{
                    filter: "agTextColumnFilter",
                    resizable: true,
                    sortable: true,
                    headerComponentParams: {
                      template:
                        '<div class="ag-cell-label-container" role="presentation">' +
                        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                        "  </div>" +
                        "</div>",
                    },
                  }}
                  frameworkComponents={{
                    actionButtonsRenderer: ActionButtonsRenderer,
                    seoStatusRenderer: SEOStatusRenderer,
                  }}
                  pagination={true}
                  paginationPageSize={10}
                  suppressDragLeaveHidesColumns={true}
                >
                  <AgGridColumn headerName="File" field="name" width={220} />
                  <AgGridColumn
                    headerName="Uploaded On"
                    field="createdAt"
                    cellRenderer={({ value }) =>
                      moment(value).format("MM-DD-YYYY")
                    }
                    width={160}
                  />
                  <AgGridColumn
                    headerName="Status"
                    field="status"
                    width={140}
                  />
                  <AgGridColumn
                    cellRenderer="seoStatusRenderer"
                    headerName="SEO Status"
                    field="seoStatus"
                    width={380}
                  />
                  <AgGridColumn
                    headerName="User"
                    field="uploader"
                    width={160}
                  />
                  <AgGridColumn
                    cellRenderer="actionButtonsRenderer"
                    filter={false}
                    sortable={false}
                    width={220}
                  />
                </AgGridReact>
              </div>
            )}
          </Grid>
        </Grid>
        <Dialog
          open={showDialog}
          onClose={handleDialogClose}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {"Upload File"}
              <CloseOutlined
                onClick={handleDialogClose}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
          </DialogTitle>
          <DialogContent
            style={{
              padding: "0px",
              paddingBottom: "8px",
            }}
          >
            <DialogContentText style={{ margin: "0px", padding: "0px 32px" }}>
              {dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                const formData = new FormData();
                formData.append("file", uploadFile);
                formData.append("filename", uploadFile.name);
                formData.append("user", userState.userData.email);
                const upload = await axios({
                  method: "post",
                  url: BASE_URL + api.uploadSeoUpdateChecks,
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "multipart/form-data",
                  },
                  data: formData,
                });
                if (upload.data?.status === "Success") {
                  handleDialogClose();
                  window.alert(
                    "Upload success. You will be notified once processing completed."
                  );
                  await loadRowData();
                } else {
                  handleDialogClose();
                  window.alert("Upload failed. Try again.");
                  await loadRowData();
                }
              }}
              variant="contained"
              color="primary"
              size="small"
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
