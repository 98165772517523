import React, { useState, forwardRef } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import moment from "moment";
import { Button, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";

export default forwardRef(function ChangesTables(props) {
  const [showProducts, setShowProducts] = useState(false);
  const [products, setProducts] = useState([]);

  const ActionButtonsRenderer = (param) => {
    return (
      <>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            setShowProducts(true);
            setProducts(param.data.products);
          }}
        >
          View Changed Products
        </Button>
      </>
    );
  };

  return (
    <div
      className="ag-theme-material ams-campaigns"
      style={{ height: 620, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        rowData={props.tableData}
        suppressExcelExport={true}
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        pagination={true}
        paginationPageSize={50}
        frameworkComponents={{
          actionButtonsRenderer: ActionButtonsRenderer,
        }}
        rowStyle={{ background: "white" }}
        fullWidth
        suppressDragLeaveHidesColumns={true}
        suppressLoadingOverlay
      >
        <AgGridColumn headerName="Campaign" field="campaignName" width={400} />
        <AgGridColumn
          headerName="Action"
          field="action"
          valueFormatter={(param) =>
            `${param.value === "ADD" ? "Added" : param.value === "REMOVE" ? "Removed" : param.value === "PAUSE" ? "Paused" : "Unknown"}`
          }
          width={100}
        />
        <AgGridColumn
          headerName="Changed At"
          field="updatedAt"
          valueFormatter={(param) => moment(param.value).format("MM-DD-YYYY")}
          filter={false}
          width={150}
        />
        <AgGridColumn headerName="Changed By" field="user" width={200} />
        <AgGridColumn
          filter={false}
          sort={false}
          width={300}
          cellRenderer="actionButtonsRenderer"
        />
      </AgGridReact>
      <Dialog
        open={showProducts}
        onClose={() => {
          setShowProducts(false);
          setProducts([]);
        }}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {"Changed Products"}
            <CloseOutlined
              onClick={() => {
                setShowProducts(false);
                setProducts([]);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="ag-theme-material ams-campaigns" style={{ height: 400, width: "100%" }}>
            <AgGridReact
              rowData={products}
              suppressExcelExport={true}
              defaultColDef={{
                filter: "agTextColumnFilter",
                resizable: true,
                sortable: true,
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    "  </div>" +
                    "</div>",
                },
              }}
              pagination={true}
              paginationPageSize={10}
              rowStyle={{ background: "white" }}
              fullWidth
              suppressDragLeaveHidesColumns={true}
              suppressLoadingOverlay
            >
              <AgGridColumn headerName="ASIN" field="asin" width={250} />
              <AgGridColumn headerName="SKU" field="sku" width={250} />
            </AgGridReact>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
});
