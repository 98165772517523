import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Container,
  Button,
  LinearProgress,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Backdrop,
  Link,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import "react-responsive-modal/styles.css";
import moment from "moment";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import _ from "lodash";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table/Table";
import ChangesTable from "./components/ChangesTable/ChangesTable";
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import { amsAdsUpdateBulk } from "../../http/api";

export default function BulkManageAmsCampaigns(props) {
  const classes = useStyles();
  const vendorState = useVendorState();
  const [mainTableData, setMainTableData] = useState([]);
  const [catalogData, setCatalogData] = useState([]);
  const [changelogData, setChangelogData] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [viewTable, setViewTable] = useState("CAMPAIGNS");
  const [showManageDialog, setShowManageDialog] = useState(false);
  const [selectedCatalog, setSelectedCatalog] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [isCampaignFilterInProgress, setIsCampaignFilterInProgress] = useState(false);
  const [isCampaignsUpdateInProgress, setIsCampaignsUpdateInProgress,] = useState(false);
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleDialogSubmitAction = async (action) => {
    try {
      setIsCampaignsUpdateInProgress(true);
      const response = await amsAdsUpdateBulk(
        vendorState?.selected,
        action,
        selectedCampaigns,
        selectedCatalog.map((item) => {
          return {
            asin: item.asin,
            sku: item.sku,
          };
        })
      );
      if (response.status === "Success") {
        window.alert("Updates completed successfully");
      } else {
        window.alert("Updates failed, try again");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsCampaignsUpdateInProgress(false);
    }
  };

  const loadData = async () => {
    try {
      setFullPageLoader(true);
      const params = {
        vendorCode: vendorState?.selected,
        campaignType: "sp",
        campaignState: "ENABLED",
        startDate: moment()
          .set("year", 2023)
          .startOf("year")
          .format("MM-DD-YYYY"),
      };
      axios
        .get(BASE_URL + api.getAMSCampaigns, tokenConfig(params))
        .then((result) => {
          if (result.data.data) {
            setMainTableData(result.data.data);
            setFullPageLoader(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setFullPageLoader(false);
        });
    } catch (error) {
      console.error(error);
      setFullPageLoader(false);
    }
  };

  const loadCatalogData = async () => {
    try {
      const params = {
        vendorCode: vendorState?.selected,
      };
      axios
        .get(BASE_URL + api.fetchCatalogBase, tokenConfig(params))
        .then((result) => {
          const catalogItems = vendorState?.selected?.endsWith("_SC")
            ? _.uniqBy(result?.data?.data || [], "sku")
            : _.uniqBy(result?.data?.data || [], "asin");
          setCatalogData(_.sortBy(catalogItems, "itemName"));
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const loadChangesData = async () => {
    try {
      const params = {
        vendorCode: vendorState?.selected,
      };
      axios
        .get(BASE_URL + api.fetchAMSCampaignsChangelog, tokenConfig(params))
        .then((result) => {
          setChangelogData(result?.data?.data || []);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (vendorState.selected) {
      loadData();
      loadCatalogData();
      loadChangesData();
    }
    return () => {
      setMainTableData([]);
      setCatalogData([]);
      setChangelogData([]);
      setSelectedCatalog([]);
      setSelectedCampaigns([]);
    };
  }, [vendorState.selected]);

  return (
    <Container maxWidth={false}>
      <PageTitle
        title="Bulk Manage AMS Campaigns"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              AMS
            </Link>
            <Typography className={classes.link} color="primary">
              Bulk Manage AMS Campaigns
            </Typography>
          </Breadcrumbs>
        }
      />
      <Grid container spacing={4} className="ams_campaign_grid">
        <Grid item xs={12} className="ams_campaign_grid_item">
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            header={
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => setShowManageDialog(true)}
                  disabled={mainTableData.length === 0}
                >
                  Search Products
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={async () => {
                    if (viewTable === "CAMPAIGNS") {
                      await loadChangesData();
                    };
                    setViewTable(
                      viewTable === "CAMPAIGNS" ? "CHANGES" : "CAMPAIGNS"
                    );
                  }}
                >
                  {`View ${
                    viewTable === "CAMPAIGNS" ? "Changelogs" : "Campaigns"
                  }`}
                </Button>
              </div>
            }
          >
            {viewTable === "CAMPAIGNS" && <Table tableData={mainTableData} />}
            {viewTable === "CHANGES" && (<ChangesTable tableData={changelogData} />)}
          </Widget>
        </Grid>
      </Grid>
      <Backdrop
        className={classes.backdrop}
        open={fullPageLoader}
        onClick={() => setFullPageLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={showManageDialog}
        onClose={() => {
          setShowManageDialog(false);
          setSelectedCatalog([]);
          setSelectedCampaigns([]);
          setIsCampaignFilterInProgress(false);
        }}
        fullWidth={true}
        maxWidth={"md"}
        style={{
          pointerEvents: isCampaignsUpdateInProgress ? "none" : undefined,
        }}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {"Search Products"}
            <CloseOutlined
              onClick={() => {
                setShowManageDialog(false);
                setSelectedCatalog([]);
                setSelectedCampaigns([]);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </DialogTitle>
        {isCampaignsUpdateInProgress && (<LinearProgress style={{ height: "12px" }} />)}
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <TextField
              label={"ASINs"}
              variant="outlined"
              multiline={true}
              minRows={2}
              maxRows={6}
              placeholder="Fill ASINs to select"
              onChange={(e) => {
                const asins = e.target.value;
                const asinsList = asins.replaceAll("\n", ",").replaceAll(" ", ",").split(",");
                setSelectedCatalog(_.uniqBy([...selectedCatalog, ...catalogData.filter((item) => asinsList.includes(item.asin))], c => [c.asin, c.sku].join()));
              }}
            />
          <div
            style={{
              display: "flex",
              gap: "8px",
              width: "100%",
            }}
          >
            <Autocomplete
              multiple
              options={catalogData}
              getOptionLabel={(option) =>
                `${option.asin}(${option.sku}) ${option.itemName}`
              }
              defaultValue={[]}
              value={selectedCatalog}
              onChange={(event, value) => setSelectedCatalog(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Search by ASIN/Title"
                />
              )}
              style={{
                width: "85%",
              }}
              size="small"
            />
            <Button
              color="primary"
              variant="text"
              component="span"
              onClick={async () => {
                try {
                  setIsCampaignFilterInProgress(true);
                  const params = {
                    vendorCode: vendorState?.selected,
                    asins: selectedCatalog.map((item) => item.asin).join(","),
                  };
                  const result = await axios.get(
                    BASE_URL + api.searchAMSCampaigns,
                    tokenConfig(params)
                  );
                  gridApi.forEachNode((row) => {
                    if (
                      (result?.data?.data || []).includes(row?.data?.campaignId)
                    ) {
                      row.setSelected(true);
                    }
                  });
                } catch (error) {
                  console.error(error);
                } finally {
                  setIsCampaignFilterInProgress(false);
                }
              }}
              disabled={
                selectedCatalog.length === 0 || isCampaignFilterInProgress
              }
              style={{
                textTransform: "unset",
                width: "15%",
                alignSelf: "end",
              }}
            >
              Filter Campaigns
              <CircularProgress
                color="inherit"
                style={{
                  display: isCampaignFilterInProgress ? "block" : "none",
                  width: "16px",
                  height: "16px",
                  marginLeft: "4px",
                }}
              />
            </Button>
          </div>
        <Typography variant="caption">
          Filter Campaigns - Selects campaigns that contains the searched products
        </Typography>
          <Typography>
            {`Selected ${selectedCampaigns.length} campaigns`}
          </Typography>
          <div
            className="ag-theme-material"
            style={{ height: 620, width: "100%" }}
          >
            <AgGridReact
              ref={gridRef}
              onGridReady={onGridReady}
              defaultColDef={{
                resizable: true,
                sortable: true,
                filter: "agTextColumnFilter",
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    "  </div>" +
                    "</div>",
                },
              }}
              rowData={mainTableData}
              rowSelection="multiple"
              onSelectionChanged={() =>
                setSelectedCampaigns(
                  gridApi.getSelectedRows().map((item) => item.campaignId)
                )
              }
              headerHeight={50}
              pagination={true}
              paginationPageSize={10}
              suppressRowClickSelection={true}
            >
              <AgGridColumn
                headerName=""
                width={50}
                headerCheckboxSelection={true}
                pinned="left"
                checkboxSelection={true}
              />
              <AgGridColumn headerName="Campaign" field="name" width={600} />
            </AgGridReact>
          </div>
        </DialogContent>
        <Typography variant="caption" style={{ padding: "0px 20px" }}>
          REMOVE - Removes (archives) selected products from the campaigns selected,
          PAUSE - Pauses selected products to the campaigns selected,
          ADD - Adds selected products to the campaigns selected
        </Typography>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => handleDialogSubmitAction("REMOVE")}
            disabled={
              selectedCatalog.length === 0 ||
              selectedCampaigns.length === 0 ||
              isCampaignsUpdateInProgress
            }
          >
            Remove
          </Button>
          <Button
            color="primary"
            onClick={() => handleDialogSubmitAction("PAUSE")}
            disabled={
              selectedCatalog.length === 0 ||
              selectedCampaigns.length === 0 ||
              isCampaignsUpdateInProgress
            }
          >
            Pause
          </Button>
          <Button
            color="primary"
            onClick={() => handleDialogSubmitAction("ADD")}
            disabled={
              selectedCatalog.length === 0 ||
              selectedCampaigns.length === 0 ||
              isCampaignsUpdateInProgress
            }
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
