/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  Card,
  CardContent,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Collapse,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "date-fns";
import axios from "axios";
import moment from "moment";

//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";

// components
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";

import useStyles from "./styles";

import { makeStyles } from "@material-ui/styles";

const useMetricStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  cardFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 14,
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  label: {
    fontWeight: "bold",
    marginRight: theme.spacing(1),
  },
  value: {
    marginRight: theme.spacing(2),
  },
}));

const MetricCard = ({ header, obj }) => {
  const classes = useMetricStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function flattenObj(rootKey, obj) {
    if (typeof obj != "object") {
      return obj;
    }
    let flatObj = {};
    for (const [key, value] of Object.entries(obj)) {
      let prefix = rootKey + key.replace(/^\w/, (x) => x.toUpperCase());
      if (typeof value != "object") {
        flatObj[prefix] = value;
        continue;
      }
      flatObj = {
        ...flatObj,
        ...flattenObj(prefix, value),
      };
    }
    return flatObj;
  }

  const data = flattenObj("", obj);

  const convertKey = (key) =>
    key
      .replaceAll(/([A-Z])/g, (x) => ` ${x}`)
      .replace(/(^\w)/, (x) => x.toUpperCase())
      .replace(/(\d+)d/, (x) => ` ${x.slice(0, -1)} days`);

  const title = convertKey(header);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box
          marginBottom={2}
          className={classes.cardHeader}
          onClick={handleExpandClick}
        >
          <Typography className={classes.title} variant="h6">
            {title}
            <Typography
              style={{
                fontSize: 14,
              }}
              variant="body1"
            >
              Target: {data.targetCondition == "LESS_THAN" ? "under" : "over"}{" "}
              {data.TargetValue} %
            </Typography>
          </Typography>
          {data.Status == "GOOD" ||
          data.AhrStatus == "GREAT" ||
          data.WarningsCount == 0 ? (
            <SuccessIcon style={{ color: "green" }} />
          ) : (
            <ErrorIcon style={{ color: "red" }} />
          )}
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <TableContainer>
            <Table>
              <TableBody>
                {Object.entries(data).map(([key, value]) => (
                  <TableRow key={key} component="th" scope="row">
                    <TableCell align="left">{convertKey(key)}</TableCell>
                    <TableCell align="left">{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <Box marginTop={2} className={classes.cardFooter}>
            <Typography
              style={{
                fontSize: 14,
              }}
              variant="subtitle1"
            >
              Time Window:{" "}
              {moment(data.ReportingDateTo, "YYYY-MM-DD").diff(
                moment(data.ReportingDateFrom, "YYYY-MM-DD"),
                "days"
              )}{" "}
              days
            </Typography>
            |
            <Typography
              style={{
                fontSize: 14,
              }}
              variant="subtitle1"
            >
              Report Date: {data.ReportingDateFrom} - {data.ReportingDateTo}
            </Typography>
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default function AccountHealth(props) {
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [data, setData] = useState({});
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [isLoadingMetricsTableData, setIsLoadingMetricsTableData] = useState(false);
  const [metricsTableData, setMetricsTableData] = useState([]);
  const [isViewMoreExpanded, setIsViewMoreExpanded] = useState(false);
  const [isLoadingViewMoreData, setIsLoadingViewMoreData] = useState(false);
  const [viewMoreExpandedTableData, setViewMoreExpandedTableData] = useState([]);
  const [viewMoreExpandedTitle, setViewMoreExpandedTitle] = useState("");
  const [viewMoreExpandedDetail, setViewMoreExpandedDetail] = useState("");

  const ViewMoreRenderer = (param) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ textTransform: "capitalize" }}
        onClick={async () => {
          setIsViewMoreExpanded(true);
          setViewMoreExpandedTitle(param.data.metric);
          setViewMoreExpandedDetail(`${param.data.detail}`);
          setIsLoadingViewMoreData(true);
          const response = await axios.get(
            BASE_URL + `/api/sp/accountHealthCheck/metrics/details`,
            tokenConfig({
              vendorCode: param.data.vendorCode,
              metricKey: param.data.metricKey,
            })
          );

          if (response?.data?.data) {
            const data = response.data.data;
            console.log(data);
            setViewMoreExpandedTableData(data);
          }
          setIsLoadingViewMoreData(false);
          setViewMoreExpandedDetail(`${param.data.detail} (last updated on ${param.data.lastUpdated})`);
        }}
      >
        View More
      </Button>
    );
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setFullPageLoader(true);
        setIsLoadingMetricsTableData(true);
        const response = await axios.get(
          BASE_URL + `/api/sp/accountHealthCheck/metrics`,
          // api.sellerPerformance,
          tokenConfig({
            vendorCode: vendorState.selected,
          })
        );

        if (response?.data?.data) {
          const data = response.data.data;
          // setData(data);
          setMetricsTableData([...data.map((item) => {
            return {
              ...item,
              vendorCode: vendorState.selected,
            }
          })]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setFullPageLoader(false);
        setIsLoadingMetricsTableData(false);
      }
    }

    if (vendorState.selected) {
      fetchData();
    }

    return () => {
      setData({});
    };
  }, [vendorState.selected]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Account Health"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                {/* SP Reports */}
                Operations
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                {/* Seller Performance */}
                Account Health
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              {/* <div className={classes.headerRight}>
                <Typography variant="body1" color="textSecondary">
                  Account Status:
                  <Typography
                    style={{
                      fontSize: 12,
                    }}
                    variant="body1"
                  >
                    Updated On: {moment(data.updatedAt).format("YYYY-MM-DD")}
                  </Typography>
                </Typography>
                {data.accountStatus !== "NORMAL" ? (
                  <ErrorIcon style={{ color: "red" }} />
                ) : (
                  <SuccessIcon style={{ color: "green" }} />
                )}
                <Typography variant="body2">{data.accountStatus}</Typography>
              </div> */}
            </>
          }
        />
        {/* <Grid container spacing={4}>
          {Object.entries(data).map(([key, value]) => {
            if (typeof value != "object") return undefined;

            return (
              <Grid item xs={4}>
                <MetricCard header={key} obj={value} />
              </Grid>
            );
          })}
        </Grid>
        <Backdrop className={classes.backdrop} open={fullPageLoader}>
          <CircularProgress color="inherit" />
        </Backdrop> */}

        <div className="ag-theme-material" style={{ height: 620, width: "100%" }}>
        {isLoadingMetricsTableData
          ? 
            <div style={{ background: "#FFFFFF" }}>
              <CircularProgress color="inherit" style={{ marginTop: "25%", marginLeft: "50%", marginBottom: "25%", }} />
            </div>
          :
            <AgGridReact
              defaultColDef={{
                resizable: true,
                sortable: true,
                filter: "agTextColumnFilter",
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    "  </div>" +
                    "</div>",
                },
              }}
              rowData={metricsTableData}
              headerHeight={50}
              pagination={true}
              paginationPageSize={50}
              frameworkComponents={{
                viewMoreRenderer: ViewMoreRenderer,
              }}
            >
              <AgGridColumn headerName="Metric" field="metric" width={200} />
              <AgGridColumn headerName="Detail" field="detail" width={520} />
              <AgGridColumn headerName="Value" field="unit" width={120} />
              <AgGridColumn
                headerName="Last Updated"
                field="lastUpdated"
                width={160}
              />
              <AgGridColumn
                cellRenderer="viewMoreRenderer"
                width={140}
              />
            </AgGridReact>
        }</div>
        <Dialog
          open={isViewMoreExpanded}
          onClose={() => {
            setIsViewMoreExpanded(false);
            setViewMoreExpandedTableData([]);
            setViewMoreExpandedTitle("");
            setViewMoreExpandedDetail("");
          }}
          fullWidth={true}
          maxWidth={"md"}
        >
          <Typography style={{ padding: "16px 16px 0px", }}>
            {viewMoreExpandedTitle}
          </Typography>
          <Typography variant="caption" style={{ padding: "16px 12px 0px", }}>
            {viewMoreExpandedDetail}
          </Typography>
          <Divider/>
          <DialogContent
            style={{
              display: "flex",
              gap: "12px",
            }}
          >
            {isLoadingViewMoreData ? <CircularProgress color="inherit" style={{ marginTop: "12.5%", marginLeft: "50%", marginBottom: "12.5%" }} /> :
            <div className="ag-theme-material" style={{ height: 540, width: "100%" }}>
              <AgGridReact
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  filter: "agTextColumnFilter",
                  headerComponentParams: {
                    template:
                      '<div class="ag-cell-label-container" role="presentation">' +
                      '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                      '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                      '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                      '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                      '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                      '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                      '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                      '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                      "  </div>" +
                      "</div>",
                  },
                }}
                rowData={viewMoreExpandedTableData}
                headerHeight={50}
                pagination={true}
                paginationPageSize={10}
              >
                {Object.keys(viewMoreExpandedTableData?.[0] || {}).map((item) => {
                  return <AgGridColumn headerName={item} field={item} width={item === "Title" ? 400 : 200}/>;
                })}
              </AgGridReact>
            </div>}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setIsViewMoreExpanded(false);
                setViewMoreExpandedTableData([]);
                setViewMoreExpandedTitle("");
                setViewMoreExpandedDetail("");
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
