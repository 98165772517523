export const modifyMapViolatersData = (modifyMapViolatersData) => {
    const { threePData, productDetails=[] } = modifyMapViolatersData;
    const mapViolaterObj = {}
    productDetails.forEach((ele, index) => {
        mapViolaterObj[ele?.asin] = ele?.mapPrice
    })
    return threePData.map(ele => {
        return {
            asin: ele?.asin?.trim(),
            sellerId: ele?.sellerId,
            offerPrice: ele?.offerPrice,
            mapPrice: mapViolaterObj[ele?.asin?.trim()],
            sku: productDetails.find((elem) => elem?.asin === ele?.asin)?.sku || '',
            violated: Number(ele?.offerPrice) > Number(mapViolaterObj[ele?.asin?.trim()])
        }
    })

}