// eslint-disable-next-line import/no-anonymous-default-export
export default {
    // getAllVendors:"/api/get/vendorList",
    getCache:"/api/get/",
    setCache:"/api/set/",
    loginUser:"/api/user/login",
    registerUser:"/api/user/register",
    listUsers:"/api/user/fetchAll",
    updateUser: "/api/user/updateUser",
    userVendorConfigs:"/api/user/vendorConfigs",
    forgotPassword:"/api/user/forgotPassword",
    resetPassword:"/api/user/resetPassword",
    uploadProductDetails:"/api/productDetails/upload",
    uploadProductDetailsV2:"/api/productDetails/upload/v2",
    uploadASINSKUMapping:"/api/productDetails/ASINSKUMapping/upload",
    uploadASINMapPrice:"/api/mapViolater/upload",
    uploadProductDetailsHiRes:"/api/productHiResImages/upload",
    listProductDetails:"/api/productDetails/list/",
    listProductDetailsHiRes:"/api/productHiResImages/list/",
    //download
    downloadProductDetails:"/api/productDetails/download/",
    downloadProductDetailsHiRes:"/api/productHiResImages/download/",
    getOrderMetrics: "/api/orderMetrics/fetch",
    getOrderMetricsASIN: "/api/orderMetrics/fetch/asin" ,
    getASINs: "/api/orderMetrics/fetch/asins",
    getShareOfVoice: "/api/ShareOfVoice/fetch" ,
    getShareOfVoiceRanges: "/api/ShareOfVoice/fetch/ranges",
    getBrandSegmentByDay: "/api/brandSegments/fetch",
    getBrandSegmentByDayRanges: "/api/brandSegments/fetch/ranges",
    getMerchantListingsReport: "/api/merchantListingsReport/fetch",
    getKeywordsByASIN: "/api/keywordsByASIN/fetch",
    getKeywordsByASINRanges: "/api/keywordsByASIN/fetch/ranges",
    getSalesEstimateData: "/api/salesEstimateData/fetch",
    getSalesEstimateDataRanges: "/api/salesEstimateData/fetch/ranges",
    getPriceWarData: "/api/salesEstimateData/price-war/fetch",
    getPriceWarDataRanges: "/api/salesEstimateData/price-war/fetch/ranges",
    getOrderMetricsASINPIR: "/api/orderMetrics/fetch/asin/pir",
    getOrderMetricsASINPIRRanges: "/api/orderMetrics/fetch/asin/pir/ranges",
    getCompetitionSalesByASIN: "/api/salesEstimateData/fetch/aggData",
    getProductReviews: "/api/amazonProductReviews/fetch",
    getProductReviewsRanges: "/api/amazonProductReviews/fetch/ranges",
    getEventNotifications: "/api/eventNotification/fetch",
    getEventNotificationTypes: "/api/eventNotification/fetch/notificationtypes", 
    getEventNotificationsTypeData: "/api/eventNotification/fetch/notifications",
    getCohesityNotifications: "/api/cohesityNotification/fetch",
    getCohesityNotificationTypes: "/api/cohesityNotification/fetch/notificationtypes", 
    getCohesityNotificationsTypeData: "/api/cohesityNotification/fetch/notifications",
    getthreep: "/api/threep/fetch" ,
    getthreepRanges: "/api/threep/fetch/ranges",
    getMapViolaterRanges: "/api/mapViolater/fetch/ranges",
    getMapViolaters: "/api/mapViolater/fetch",
    getProductReviewsAll: "/api/amazonProductReviews/fetch/all",
    getOrderMetricsStats: "/api/orderMetrics/fetch/stats",
    pullOrderMetricsData: "/api/orderMetrics/pull/data",
    getReachTrend: "/api/ams/reachTrend/fetch",
    saveVendorSettings: "/api/amsPost/saveVendorSettings",
    updateVendorSettings: "/api/amsPost/updateVendorSettings",
    getVendorSettings: "/api/ams/vendorSettings",
    getSubCategories: "/api/ams/subCategories",
    saveProductCatalogs: "/api/amsPost/saveProductCatalogs",
    getAMSCampaigns: "/api/ams/campaigns/fetch",
    keywordPerformance: "/api/ams/keyword-reports",
    asinPerformance: "/api/ams/asin-reports",
    fetchPortfolios: "/api/ams/fetchPortfolios",
    searchTermPerformance: "/api/ams/search-term-reports",
    productTargetPerformance: "/api/ams/product-target-reports",
    placementAnalysis: "/api/ams/placementAnalysis",
    getAMSCampaign: "/api/ams/campaign/fetch",
    getCampaign: "/api/ams/getCampaign",
    editAMSCampaigns: "/api/ams/campaigns/edit",
    runOptimization: "/api/amsPost/runOptimization",
    runOptimizationImmediately: "/api/amsPost/runOptimizationsImmediately",
    stopOptimization: "/api/amsPost/stopOptimization",
    startOptimizations: "/api/amsPost/startOptimizations",
    stopOptimizations: "/api/amsPost/stopOptimizations",
    createScheduledCampaign: "/api/amsPost/createScheduledCampaign",
    amsScreenOne: "/api/ams/screen1/fetch",
    amsScreenTwo: "/api/ams/screen2/fetch",
    amsCreateAutoCampaign: "/api/amsPost/createAutoCampaign",
    amsCreateManualCampaign: "/api/amsPost/createManualCampaign",
    getAMSSubCategories:"/api/ams/subCategories",
    getAMSProducts:"/api/ams/products",
    getAMSCampaignVersions: "/api/ams/campaign/versions",
    getApiConfig: "/api/cronsManagement/fetch" ,
    postApiConfig: "/api/cronsManagement/save" ,
    loginDetails: "/api/user/loginDetails",
    getBrandDetails: "/api/brand-finder/list/",
    domainsLLMValidationList: "/api/domainsLLMValidation/list/",
    downloadDomainDetails: "/api/domainsLLMValidation/download/",
    uploadDomainDetails: "/api/domainsLLMValidation/upload",
    retryDomainDetails: "/api/domainsLLMValidation/retry_upload",
    uploadBrandDetails: "/api/brand-finder/upload",
    retryBrandDetails: "/api/brand-finder/retry_upload",
    downloadBrandDetails: "/api/brand-finder/download/",
    listBlockedDomains: "/api/blockDomains/list",
    blockDomain: "/api/blockDomains",
    updateBlockedDomains: "/api/blockDomains/update",
    uploadDomainsSheet: "/api/blockDomains/upload",
    spSellerCentral: "/api/sp/sellerCentral",
    spGeographicInsights: "/api/sp/geographicInsights",
    spVendorSalesReports: "/api/sp/vendorCentral",
    spGeographicDateRange: "/api/sp/geographicDateRange",
    spDateRange: "/api/sp/dateRange",
    spSellerCentralDateRange: "/api/sp/sellerCentralDateRange",
    listContactFinder: "/api/contactFinder/list/",
    uploadContactFinder: "/api/contactFinder/upload",
    downloadContactFinder: "/api/contactFinder/download/",
    listBrands: "/api/brand-finder/brands",
    searchContacts: "/api/contactFinder/search",
    listInventoryReports: "/api/sp/inventoryReports",
    getAMSCampaignReports: "/api/ams/campaign-reports/",
    getAMSCampaignReportsDateRange: "/api/ams/campaign-reports/date-range/",
    listEmptyDomains: "/api/emptyDomains/list",
    updateDomainStatus: "/api/emptyDomains/status",
    updateDomainComment: "/api/emptyDomains/comment",
    listContactFinder2: "/api/contactFinder/v2/list/",
    uploadContactFinder2: "/api/contactFinder/v2/upload",
    downloadContactFinder2: "/api/contactFinder/v2/download/",
    searchContactsFromApolloApi: "/api/searchContacts",
    searchOrganisationsFromApolloApi: "/api/org/enrich",
    enrichContactsFromApolloApi: "/api/searchContacts/enrich",
    spListing: "/api/sp/product/listing/",
    spListingVersions: "/api/sp/product/listing/versions/",
    listAmsProfiles: "/api/amsProfiles/list",
    updateAmsProfiles: "/api/amsProfiles/update",
    brandFinderGlobalUpload: "/api/brandFinderGlobal/upload",
    brandFinderGlobalDownload: "/api/brandFinderGlobal/download/",
    brandFinderGlobalRetry: "/api/brandFinderGlobal/retry_upload/",
    brandFinderGlobalList: "/api/brandFinderGlobal/list/",
    categoryScrappingUpload: "/api/categoryScrapping/upload",
    categoryScrappingUploadRetry: "/api/categoryScrapping/retry_upload",
    categoryScrappingDownload: "/api/categoryScrapping/download/",
    categoryScrappingList: "/api/categoryScrapping/list/",
    categoryScrappingDownloadList: "/api/categoryScrapping/downloadMergedFiles/",
    getCategoryFilesList: "/api/brand-finder/categoryList/",
    categoryDomainScrappingUpload: "/api/categoryDomainScrapping/upload",
    categoryDomainScrappingUploadRetry: "/api/categoryDomainScrapping/retry_upload",
    categoryDomainScrappingDownload: "/api/categoryDomainScrapping/download/",
    categoryDomainScrappingList: "/api/categoryDomainScrapping/list/",
    categoryDomainScrappingDownloadList: "/api/categoryDomainScrapping/downloadMergedFiles/",
    getCategoryDomainFilesList: "/api/categoryDomainScrapping/brandDomainFilesList/",
    brandDomainFinderUpload: "/api/brandDomainFinder/upload",
    brandDomainFinderList: "/api/brandDomainFinder/list/",
    brandDomainFinderDownload: "/api/brandDomainFinder/download/",
    brandDomainFinderRetry: "/api/brandDomainFinder/retry_upload/",
    catalogGetAPI: "/api/sp/catalog",
    updateSDProgram: "/api/sp/updateSDProgram",
    exportActivities: "/api/sp/exportActivities",
    getAMSDayPartings: "/api/ams/getDayPartings",
    createDayParting: "/api/amsPost/createDayParting",
    removeDayParting: "/api/amsPost/removeDayParting",
    pullThreePReport: "/api/threep/pull/data",
    pullShareOfVoiceReport: "/api/shareOfVoice/pull/data",
    pullGeoReports: "/api/sp/geographicInsights/pull/data",
    fetchCampaignReports: "/api/ams/campaignReports/pull/data",
    fetchSalesTrafficReports: "/api/sp/sellerCentral/pull/data",
    pullVendorSalesReport: "/api/sp/vendorSalesReport/pull/data",
    catalogRefresh: "/api/sp/catalog/refresh",
    pullJSSegmentReports: "/api/js/pull/reports",
    checkJSSegment: "/api/js/checkSegment",
    createJSSegment: "/api/js/createSegment",
    importJSSegment: "/api/js/importSegment",
    editJSSegment: "/api/js/editSegment",
    fetchJSSegments: "/api/js/segments",
    getJSSegment: "/api/js/segment",
    deleteJSSegment: "/api/js/deleteSegment",
    getCompetitionBenchmarkData: "/api/js/competitionBenchmark",
    getProductsAndBrands: "/api/js/productsAndBrands",
    strandedInventory: "/api/sp/stranded-inventory",
    sellerPerformance: "/api/sp/seller-performance",
    fbaReimbursements: "/api/sp/fba-reimbursements",
    getAmsOnboardingProfiles: "/api/amsVendorOnboarding/amsProfiles",
    generateVendorCode: "/api/amsVendorOnboarding/generateVendorCode",
    listCustomers: "/api/amsVendorOnboarding/listCustomers",
    addToAms: "/api/amsVendorOnboarding/addToAms",
    deleteFromAms: "/api/amsVendorOnboarding/deleteVendor",
    listBudgetChanges: "/api/ams/budgetChanges",
    fetchAmsDataSummary: "/api/ams/summary",
    cohesityLogs: "/api/cohesity/cohesityLogs",
    fetchCatalogActivities: "/api/sp/fetchCatalogActivities",
    changeVariants: "/api/sp/product/changeVariants",
    uploadBaseline: "/api/sp/product/uploadBaseline",
    getShipments: "/api/fbaShipment/getShipments",
    getAddressList: "/api/fbaShipment/getAddressList",
    setDefaultAddress: "/api/fbaShipment/setDefaultAddress",
    createPlan: "/api/fbaShipment/createPlan",
    createShipment: "/api/fbaShipment/createShipment",
    getPrepInstructions: "/api/fbaShipment/getPrepInstructions",
    sendTransportDetails: "/api/fbaShipment/sendTransportDetails",
    estimateShippingCost: "/api/fbaShipment/estimateShippingCost",
    estimateShippingCostV2: "/api/fbaShipment/estimateShippingCost/v2",
    getTransportDetails: "/api/fbaShipment/getTransportDetails",
    confirmTransport: "/api/fbaShipment/confirmTransport",
    voidTransport: "/api/fbaShipment/voidTransport",
    createAddress: "/api/fbaShipment/createAddress",
    updateAddress: "/api/fbaShipment/updateAddress",
    deleteAddress: "/api/fbaShipment/deleteAddress",
    getLabels: "/api/fbaShipment/getLabels",
    getBillOfLading: "/api/fbaShipment/getBillOfLading",
    getShipmentItemsByShipmentId: "/api/fbaShipment/getShipmentItemsByShipmentId",
    cancelShipment: "/api/fbaShipment/cancelShipment",
    sendCartonContentInformation: "/api/fbaShipment/sendCartonContentInformation",
    getShipmentFromDb: "/api/fbaShipment/getShipmentFromDb",
    getCasePackDetails: "/api/fbaShipment/getCasePackDetails",
    addCasePackDetails: "/api/fbaShipment/addCasePackDetails",
    deleteCasePackDetails: "/api/fbaShipment/deleteCasePackDetails",
    updateCasePackDetails: "/api/fbaShipment/updateCasePackDetails",
    saveShipmentContact: "/api/fbaShipment/saveShipmentContact",
    getShipmentContact: "/api/fbaShipment/getShipmentContact",
    markAsShipped: "/api/fbaShipment/markAsShipped",
    sendTrackingNumbers: "/api/fbaShipment/sendTrackingNumbers",
    fetchIntegrations: "/api/user/listIntegrations",
    listCustomerConfigs: "/api/user/customerConfigs",
    listUserVendorConfigs: "/api/user/vendorConfigs",
    fetchForecastHealth: "/api/bcg/fetchForecastHealth",
    getBcgMatrixData: "/api/bcg/getBcgData",
    getBcgForecast: "/api/bcg/bcgForecast",
    getSegmentForecast: "/api/bcg/segmentForecastData",
    getSegmentForecastHealth: "/api/bcg/segmentForecastHealth",
    getASINSegmentForecast: "/api/bcg/asinSegmentForecast",
    amsForecast: "/api/amsPost/forecast",
    fetchAMSForecast: "/api/ams/forecast",
    calculateSegmentProjections: "/api/bcg/calculateSegmentProjections",
    changeASINProjection: "/api/bcg/changeASINProjection",
    overrideProjections: "/api/bcg/overrideProjections",
    downloadAutomatedForecast: "/api/bcg/downloadAutomatedForecast",
    fetchSearchTermsReport: "/api/ams/searchTerms/pull/data",
    fetchKeywordsReport: "/api/ams/keywords/pull/data",
    fetchDapiStatistics: "/api/stats/dapi",
    cohesityOptions: "/api/cohesity/cohesityOptions",
    wbrOptions: "/api/wbr/wbrOptions",
    fetchCustomerConfig: "/api/customerConfig",
    fetchReportHealth: "/api/reportsHealth/getReportHealth",
    fetchDbArchives: "/api/dbArchives",
    restoreDbArchive: "/api/dbRestore",
    fetchAllSpNotificationsSubscriptions: "/api/sp/notificationsSubscriptions",
    spNotificationsSubscriptions: "/api/sp/notifications",
    detailPage: "/api/detailPage/lists",
    detailPageBaselineDetails: "/api/detailPage/baselineDetails",
    fetchActionItems: "/api/wbr/listActionItems",
    getActionItem: "/api/wbr/getActionItem",
    addActionItem: "/api/wbr/addActionItem",
    updateActionItem: "/api/wbr/updateActionItem",
    deleteActionItem: "/api/wbr/deleteActionItem",
    keyOutputMetrics: "/api/wbr/keyOutputMetrics",
    keyInputMetrics: "/api/wbr/keyInputMetrics",
    otherKeyMetrics: "/api/wbr/otherKeyMetrics",
    progressAgainstGoalsMetrics: "/api/wbr/progressAgainstGoalsMetrics",
    fetchWbrFinanceReport: "/api/financeDisputes/report",
    uploadFinanceDisputes: "/api/financeDisputes/upload",
    fetchWbrOperationsReport: "/api/operationsTickets/report",
    uploadOperationsTickets: "/api/operationsTickets/upload",
    exportReport: "/api/wbr/exportReport",
    amsReports: "/api/wbr/amsReports",
    uploadCategory: "/api/sp/product/uploadCategory",
    fetchContents: "/api/content/lists",
    generateProductName: "/api/content/generateProductName",
    generateContents: "/api/content/generate",
    saveContents: "/api/content/save",
    sendContentsMail: "/api/content/sendmail",
    deleteContent: "/api/content/delete",
    fetchRelevantASINs: "/api/product-catalog/fetch",
    fetchCompetitorASINs: "/api/content/competitorASINs/",
    generateReviewsSummary: "/api/content/generateReviewsSummary/",
    fetchTopKeywordsByASINs: "/api/keywordsByASIN/fetch/top",
    fetchCustomerContents: "/api/content/customer/lists",
    fetchInternalContents: "/api/content/internal/lists",
    fetchContentRealTimeInfo: "/api/content/realtimeInfo",
    fetchContentChangelogs: "/api/content/changelog/fetch",
    saveContentChangelogs: "/api/content/changelog/save",
    createRule: "/api/accountManagement/rules/createRule",
    listRules: "/api/accountManagement/rules/listRules",
    getRule: "/api/accountManagement/rules/getRule",
    restartRule: "/api/accountManagement/rules/restartRule",
    updateRule: "/api/accountManagement/rules/updateRule",
    deleteRule: "/api/accountManagement/rules/deleteRule",
    getRuleDetails: "/api/accountManagement/rules/getRuleDetails",
    deactivateAccount: "/api/customerConfig/delete",
    mergeAccount: "/api/customerConfig/merge",
    pullVendorTrafficReport: "/api/sp/vendorTrafficReport/pull/data",
    cohesityAsins: "/api/cohesity/cohesityAsins",
    getWbrConfig: "/api/wbr/wbrConfig",
    saveUserGroup: "/api/accountManagement/userGroups/save",
    fetchUserGroups: "/api/accountManagement/userGroups",
    fetchUserGroupNames: "/api/accountManagement/userGroups/groups",
    updateUserGroup: "/api/accountManagement/userGroups/update/",
    deleteUserGroup: "/api/accountManagement/userGroups/delete",
    amNotificationOptions: "/api/accountManagement/notificationOptions",
    categoryList: "/api/sp/category/list",
    findIfReportsExist: "/api/accountManagement/rules/findIfReportsExist",
    pullPromotionPerformanceReport: "/api/sp/promotionPerformance/pull/data",
    pullCouponPerformanceReport: "/api/sp/couponPerformance/pull/data",
    pullProductPricingReport: "/api/sp/productPricing/pull/data",
    pullVendorNPPMarginReport: "/api/sp/vendorNPPMarginReport/pull/data",
    fetchSalesFunnelReports: "/api/salesFunnel/reports",
    pullInventoryReport: "/api/sp/inventory/pull/data",
    views: "/api/sp/content/views",
    configureDefaultRule: "/api/accountManagement/rules/configureDefaultRule",
    weeklyInsights: "/api/wbr/insights",
    uploadWbrShortageClaimsAndChargebacks: "/api/financeDisputes/upload/chargebacksAndShortageClaims",
    fetchChargebacks: "/api/financeDisputes/chargebacks",
    fetchShortageClaims: "/api/financeDisputes/shortageClaims",
    generateWeeklyInsights: "/api/accountManagement/insights/generate_report",
    pullVendorRealTimeSalesReport: "/api/sp/vendorRealTimeSales/pull/data",
    pullVendorRealTimeTrafficReport: "/api/sp/vendorRealTimeTraffic/pull/data",
    pullVendorRealTimeInventoryReport: "/api/sp/vendorRealTimeInventory/pull/data",
    updateAMRulesConfig:'/api/accountManagement/rules/updateAMRulesConfig',
    fetchMbrExport: "/api/customerConfig/mbr/export",
    generateCommissionsExport: "/api/customerConfig/commissions/export",
    changePrimaryAccounts: "/api/customerConfig/changePrimaryAccounts",
    biAccess: "/api/wbr/biAccess",
    updateMapViolatorOptions: "/api/mapViolater/updateMapViolatorOptions",
    fetchMapViolatorOptions: "/api/mapViolater/fetchMapViolatorOptions",
    saveAsinsReviewsApiConfig: "/api/cronsManagement/reviewsAsins/save",
    saveAsinsApiConfig: "/api/cronsManagement/asins/save",
    saveSegmentsApiConfig: "/api/cronsManagement/segments/save",
    saveShareOfVoicesApiConfig: "/api/cronsManagement/shareOfVoice/save",
    fetchAuditDetails: "/api/auditTool/list",
    fetchWbrFunnel: "/api/wbr/funnel",
    segmentList: "/api/js/segment/list",
    fetchProductImages:"/api/productImages/list",
    uploadProductImages:"/api/productImages/upload",
    retryProductImages:"/api/productImages/retry",
    uploadSeoUpdate:"/api/detailPage/seoUpdate/upload",
    fetchSeoUpdateChecks:"/api/detailPage/seoUpdateChecks/list",
    uploadSeoUpdateChecks:"/api/detailPage/seoUpdateChecks/upload",
    retrySeoUpdateChecks:"/api/detailPage/seoUpdateChecks/retry",
    downloadSeoUpdateChecks:"/api/detailPage/seoUpdateChecks/download",
    uploadS3CatalogProductImages:"/api/productImages/s3CatalogFavicon",
    uploadS3JsCompetitorProductImages:"/api/productImages/s3JsCompetitorFavicon",
    generateReviews:"/api/amazonProductReviews/pull/data",
    spPatchListing: "/api/sp/product/patchListing/",
    deleteAccountReportCache: "/api/customerConfig/cache/delete",
    createAccountReportCache: "/api/customerConfig/cache/create",
    uploadCatalogImage: "/api/sp/product/uploadCatalogImage",
    uploadCatalogImageZip: "/api/sp/product/catalog/imageZip/upload",
    fetchCatalogImageZip: "/api/sp/product/catalog/imageZip/list",
    retryCatalogImageZip: "/api/sp/product/catalog/imageZip/retry",
    downloadCatalogImageZipOutput:"/api/sp/catalog/imageZip/download",
    catalogImages: "/api/sp/product/catalogImages",
    checkVcVendorCode: "/api/sp/product/checkVcVendorCode",
    checkVcVendorCodeWithASIN: "/api/sp/product/checkVcVendorCodeWithASIN",
    productTypeVariationThemes: "/api/sp/product/variationThemes",
    catalogVariationsUpload:"/api/sp/catalog/variations/upload",
    fetchCatalogVariationsUpload:"/api/sp/catalog/variations/upload/list",
    retryCatalogVariations:"/api/sp/catalog/variations/retry",
    downloadCatalogVariations:"/api/sp/catalog/variations/download",
    fetchFbaInventoryRecommendations: "/api/sp/fbaInventoryRecommendations/fetch",
    fetchAccountHealthCheckOptions: "/api/customerConfig/accountHealthCheckOptions",
    saveAccountHealthCheckOptions: "/api/customerConfig/accountHealthCheckOptions",
    fetchCatalogBase:"/api/sp/catalog/base",
    searchAMSCampaigns: "/api/ams/campaigns/search",
    fetchAMSCampaignsChangelog: "/api/ams/campaigns/changelog",
    updateAmsAdsBulk: "/api/amsPost/update/bulk"
}
